import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

import "../styles/homeHouse.css";

import Logo from "../assets/Logo_black.svg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const navigation = [
  { name: "Linguagens", href: "#" },
  { name: "Cursos", href: "#" },
  { name: "Ajuda", href: "#" },
];

export function HomeLoader() {
  return (
    <div className="flex justify-center items-center h-screen">
      <div role="status">
        <svg
          className="inline mr-2 w-8 h-8 text-gray-200 animate-spin fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <div className="relative bg-gray-800 overflow-hidden">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <div className="relative pt-6 pb-6 sm:pb-6">
        <Popover>
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/">
                  <span className="sr-only">Workflow</span>
                  <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-10 md:flex md:ml-10">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="font-medium text-white hover:text-gray-300"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden md:flex">
              <Link
                to="/login"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Entrar
              </Link>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-gray-700 ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo} alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-gray-700 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-gray-400 hover:bg-gray-600"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <Link
                  to="/login"
                  className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                >
                  Entrar
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <main className="mt-16 sm:mt-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  <a
                    href="/"
                    className="inline-flex items-center text-white bg-gray-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                  >
                    <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">
                      Novidades
                    </span>
                    <span className="ml-4 text-sm">
                      Confira os lançamentos!
                    </span>
                    <ChevronRightIcon
                      className="ml-2 w-5 h-5 text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                    <span className="md:block">
                      Aprenda programação através de
                    </span>{" "}
                    <span className="text-indigo-400 md:block">Games</span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Nossa página destina-se a usuários que queiram aprender
                    programação atrevés de metodos lúdicos em gamificação.
                  </p>
                  <Link
                    type="button"
                    to="/register"
                    className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    <svg
                      className="w-6 h-6 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                    Começar agora
                  </Link>
                </div>
              </div>
              <div className="hidden lg:block relative w-0 flex-1 ml-20">
                <div className="house" id="hs">
                  {/* <div className="h-lights">
                    <div className="h-light"></div>
                    <div className="h-light"></div>
                    <div className="h-light"></div>
                    <div className="h-light"></div>
                    <div className="h-light"></div>
                    <div className="h-light"></div>
                  </div> */}
                  <div className="h-shadow"></div>
                  <div className="alt">
                    <div className="alt__front face"> </div>
                    <div className="alt__back face"> </div>
                    <div className="alt__right face"> </div>
                    <div className="alt__left face"> </div>
                    <div className="alt__top face">
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                      <div className="light"></div>
                    </div>
                    <div className="alt__bottom face"> </div>
                  </div>
                  <div className="alb">
                    <div className="alb__front face"> </div>
                    <div className="alb__back face"> </div>
                    <div className="alb__right face"> </div>
                    <div className="alb__left face"> </div>
                    <div className="alb__top face"> </div>
                    <div className="alb__bottom face"> </div>
                  </div>
                  <div className="arb">
                    <div className="arb__front face"> </div>
                    <div className="arb__back face"> </div>
                    <div className="arb__right face"> </div>
                    <div className="arb__left face"> </div>
                    <div className="arb__top face"> </div>
                    <div className="arb__bottom face"> </div>
                  </div>
                  <div className="blt">
                    <div className="blt__front face"> </div>
                    <div className="blt__back face"> </div>
                    <div className="blt__right face"> </div>
                    <div className="blt__left face"> </div>
                    <div className="blt__top face"> </div>
                    <div className="blt__bottom face"> </div>
                  </div>
                  <div className="blt2">
                    <div className="blt2__front face"> </div>
                    <div className="blt2__back face"> </div>
                    <div className="blt2__right face"> </div>
                    <div className="blt2__left face"> </div>
                    <div className="blt2__top face"> </div>
                    <div className="blt2__bottom face"> </div>
                  </div>
                  <div className="blb">
                    <div className="blb__front face"> </div>
                    <div className="blb__back face"> </div>
                    <div className="blb__right face"> </div>
                    <div className="blb__left face"> </div>
                    <div className="blb__top face"> </div>
                    <div className="blb__bottom face"> </div>
                  </div>
                  <div className="blb2">
                    <div className="blb2__front face"> </div>
                    <div className="blb2__back face"> </div>
                    <div className="blb2__right face"> </div>
                    <div className="blb2__left face"> </div>
                    <div className="blb2__top face"> </div>
                    <div className="blb2__bottom face"> </div>
                  </div>
                  <div className="puerta-c">
                    <div className="puerta">
                      <div className="puerta__front face"> </div>
                      <div className="puerta__back face"> </div>
                      <div className="puerta__right face"> </div>
                      <div className="puerta__left face"> </div>
                      <div className="puerta__top face"> </div>
                      <div className="puerta__bottom face"> </div>
                    </div>
                    <div className="puerta-l">
                      <div className="puerta-l__front face"> </div>
                      <div className="puerta-l__back face"> </div>
                      <div className="puerta-l__right face"> </div>
                      <div className="puerta-l__left face"> </div>
                      <div className="puerta-l__top face"> </div>
                      <div className="puerta-l__bottom face"> </div>
                    </div>
                    <div className="puerta-r">
                      <div className="puerta-r__front face"> </div>
                      <div className="puerta-r__back face"> </div>
                      <div className="puerta-r__right face"> </div>
                      <div className="puerta-r__left face"> </div>
                      <div className="puerta-r__top face"> </div>
                      <div className="puerta-r__bottom face"> </div>
                    </div>
                    <div className="puerta-t">
                      <div className="puerta-t__front face"> </div>
                      <div className="puerta-t__back face"> </div>
                      <div className="puerta-t__right face"> </div>
                      <div className="puerta-t__left face"> </div>
                      <div className="puerta-t__top face"> </div>
                      <div className="puerta-t__bottom face"> </div>
                    </div>
                  </div>
                  <div className="cuadro-l">
                    <div className="cuadro-l__front face"> </div>
                    <div className="cuadro-l__back face"> </div>
                    <div className="cuadro-l__right face"> </div>
                    <div className="cuadro-l__left face"> </div>
                    <div className="cuadro-l__top face"> </div>
                    <div className="cuadro-l__bottom face"> </div>
                  </div>
                  <div className="cuadro-r">
                    <div className="cuadro-r__front face"> </div>
                    <div className="cuadro-r__back face"> </div>
                    <div className="cuadro-r__right face"> </div>
                    <div className="cuadro-r__left face"> </div>
                    <div className="cuadro-r__top face"> </div>
                    <div className="cuadro-r__bottom face"> </div>
                  </div>
                  <div className="librero">
                    <div className="librero__front face"> </div>
                    <div className="librero__back face"> </div>
                    <div className="librero__right face"> </div>
                    <div className="librero__left face"> </div>
                    <div className="librero__top face"> </div>
                    <div className="librero__bottom face"> </div>
                  </div>
                  <div className="libros">
                    <div className="libro">
                      <div className="libro__front face"> </div>
                      <div className="libro__back face"> </div>
                      <div className="libro__right face"> </div>
                      <div className="libro__left face"> </div>
                      <div className="libro__top face"> </div>
                      <div className="libro__bottom face"> </div>
                    </div>
                    <div className="libro">
                      <div className="libro__front face"> </div>
                      <div className="libro__back face"> </div>
                      <div className="libro__right face"> </div>
                      <div className="libro__left face"> </div>
                      <div className="libro__top face"> </div>
                      <div className="libro__bottom face"> </div>
                    </div>
                    <div className="libro">
                      <div className="libro__front face"> </div>
                      <div className="libro__back face"> </div>
                      <div className="libro__right face"> </div>
                      <div className="libro__left face"> </div>
                      <div className="libro__top face"> </div>
                      <div className="libro__bottom face"> </div>
                    </div>
                    <div className="libro">
                      <div className="libro__front face"> </div>
                      <div className="libro__back face"> </div>
                      <div className="libro__right face"> </div>
                      <div className="libro__left face"> </div>
                      <div className="libro__top face"> </div>
                      <div className="libro__bottom face"> </div>
                    </div>
                    <div className="libro">
                      <div className="libro__front face"> </div>
                      <div className="libro__back face"> </div>
                      <div className="libro__right face"> </div>
                      <div className="libro__left face"> </div>
                      <div className="libro__top face"> </div>
                      <div className="libro__bottom face"> </div>
                    </div>
                    <div className="libro">
                      <div className="libro__front face"> </div>
                      <div className="libro__back face"> </div>
                      <div className="libro__right face"> </div>
                      <div className="libro__left face"> </div>
                      <div className="libro__top face"> </div>
                      <div className="libro__bottom face"> </div>
                    </div>
                  </div>
                  <div className="fotos">
                    <div className="foto">
                      <div className="foto__front face"> </div>
                      <div className="foto__back face"> </div>
                      <div className="foto__right face"> </div>
                      <div className="foto__left face"> </div>
                      <div className="foto__top face"> </div>
                      <div className="foto__bottom face"> </div>
                    </div>
                    <div className="foto">
                      <div className="foto__front face"> </div>
                      <div className="foto__back face"> </div>
                      <div className="foto__right face"> </div>
                      <div className="foto__left face"> </div>
                      <div className="foto__top face"> </div>
                      <div className="foto__bottom face"> </div>
                    </div>
                  </div>
                  <div className="cajas">
                    <div className="caja">
                      <div className="caja__front face"> </div>
                      <div className="caja__back face"> </div>
                      <div className="caja__right face"> </div>
                      <div className="caja__left face"> </div>
                      <div className="caja__top face"> </div>
                      <div className="caja__bottom face"> </div>
                    </div>
                    <div className="caja">
                      <div className="caja__front face"> </div>
                      <div className="caja__back face"> </div>
                      <div className="caja__right face"> </div>
                      <div className="caja__left face"> </div>
                      <div className="caja__top face"> </div>
                      <div className="caja__bottom face"> </div>
                    </div>
                    <div className="caja">
                      <div className="caja__front face"> </div>
                      <div className="caja__back face"> </div>
                      <div className="caja__right face"> </div>
                      <div className="caja__left face"> </div>
                      <div className="caja__top face"> </div>
                      <div className="caja__bottom face"> </div>
                    </div>
                  </div>
                  <div className="tv">
                    <div className="tv__front face"> </div>
                    <div className="tv__back face"> </div>
                    <div className="tv__right face"> </div>
                    <div className="tv__left face"> </div>
                    <div className="tv__top face"> </div>
                    <div className="tv__bottom face"> </div>
                  </div>
                  <div className="repisa-t">
                    <div className="repisa-t__front face"> </div>
                    <div className="repisa-t__back face"> </div>
                    <div className="repisa-t__right face"> </div>
                    <div className="repisa-t__left face"> </div>
                    <div className="repisa-t__top face"> </div>
                    <div className="repisa-t__bottom face"> </div>
                  </div>
                  <div className="repisa-b">
                    <div className="repisa-b__front face"> </div>
                    <div className="repisa-b__back face"> </div>
                    <div className="repisa-b__right face"> </div>
                    <div className="repisa-b__left face"> </div>
                    <div className="repisa-b__top face"> </div>
                    <div className="repisa-b__bottom face"> </div>
                  </div>
                  <div className="bocina-l">
                    <div className="bocina-l__front face"> </div>
                    <div className="bocina-l__back face"> </div>
                    <div className="bocina-l__right face"> </div>
                    <div className="bocina-l__left face"> </div>
                    <div className="bocina-l__top face"> </div>
                    <div className="bocina-l__bottom face"> </div>
                  </div>
                  <div className="bocina-r">
                    <div className="bocina-r__front face"> </div>
                    <div className="bocina-r__back face"> </div>
                    <div className="bocina-r__right face"> </div>
                    <div className="bocina-r__left face"> </div>
                    <div className="bocina-r__top face"> </div>
                    <div className="bocina-r__bottom face"> </div>
                  </div>
                  <div className="muro">
                    <div className="muro__front face"> </div>
                    <div className="muro__back face"> </div>
                    <div className="muro__right face"> </div>
                    <div className="muro__left face"> </div>
                    <div className="muro__top face"> </div>
                    <div className="muro__bottom face"> </div>
                  </div>
                  <div className="sillon-c">
                    <div className="sillon-b">
                      <div className="sillon-b__front face"> </div>
                      <div className="sillon-b__back face"> </div>
                      <div className="sillon-b__right face"> </div>
                      <div className="sillon-b__left face"> </div>
                      <div className="sillon-b__top face"> </div>
                      <div className="sillon-b__bottom face"> </div>
                    </div>
                    <div className="sillon-t">
                      <div className="sillon-t__front face"> </div>
                      <div className="sillon-t__back face"> </div>
                      <div className="sillon-t__right face"> </div>
                      <div className="sillon-t__left face"> </div>
                      <div className="sillon-t__top face"> </div>
                      <div className="sillon-t__bottom face"> </div>
                    </div>
                    <div className="sillon-l">
                      <div className="sillon-l__front face"> </div>
                      <div className="sillon-l__back face"> </div>
                      <div className="sillon-l__right face"> </div>
                      <div className="sillon-l__left face"> </div>
                      <div className="sillon-l__top face"> </div>
                      <div className="sillon-l__bottom face"> </div>
                    </div>
                    <div className="sillon-r">
                      <div className="sillon-r__front face"> </div>
                      <div className="sillon-r__back face"> </div>
                      <div className="sillon-r__right face"> </div>
                      <div className="sillon-r__left face"> </div>
                      <div className="sillon-r__top face"> </div>
                      <div className="sillon-r__bottom face"> </div>
                    </div>
                  </div>
                  <div className="mesa-c">
                    <div className="mesa">
                      <div className="mesa__front face"> </div>
                      <div className="mesa__back face"> </div>
                      <div className="mesa__right face"> </div>
                      <div className="mesa__left face"> </div>
                      <div className="mesa__top face"> </div>
                      <div className="mesa__bottom face"> </div>
                    </div>
                    <div className="mesa-p">
                      <div className="mesa-p__front face"> </div>
                      <div className="mesa-p__back face"> </div>
                      <div className="mesa-p__right face"> </div>
                      <div className="mesa-p__left face"> </div>
                      <div className="mesa-p__top face"> </div>
                      <div className="mesa-p__bottom face"> </div>
                    </div>
                    <div className="mesa-p">
                      <div className="mesa-p__front face"> </div>
                      <div className="mesa-p__back face"> </div>
                      <div className="mesa-p__right face"> </div>
                      <div className="mesa-p__left face"> </div>
                      <div className="mesa-p__top face"> </div>
                      <div className="mesa-p__bottom face"> </div>
                    </div>
                    <div className="mesa-p">
                      <div className="mesa-p__front face"> </div>
                      <div className="mesa-p__back face"> </div>
                      <div className="mesa-p__right face"> </div>
                      <div className="mesa-p__left face"> </div>
                      <div className="mesa-p__top face"> </div>
                      <div className="mesa-p__bottom face"> </div>
                    </div>
                    <div className="mesa-p">
                      <div className="mesa-p__front face"> </div>
                      <div className="mesa-p__back face"> </div>
                      <div className="mesa-p__right face"> </div>
                      <div className="mesa-p__left face"> </div>
                      <div className="mesa-p__top face"> </div>
                      <div className="mesa-p__bottom face"> </div>
                    </div>
                    <div className="mesa-shadow"></div>
                  </div>
                  <div className="tablet">
                    <div className="tablet__front face"> </div>
                    <div className="tablet__back face"> </div>
                    <div className="tablet__right face"> </div>
                    <div className="tablet__left face"> </div>
                    <div className="tablet__top face"> </div>
                    <div className="tablet__bottom face"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}
