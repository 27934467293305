import { Link } from "react-router-dom";
import Logo from "../assets/Logo_black.svg";

export default function Register() {
  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-8 ml-auto mr-auto lg:grid lg:gap-20 lg:py-10 lg:grid-cols-12">
          <div className="flex-col justify-between hidden col-span-6 mr-auto lg:flex xl:mb-0">
            <div>
              <Link
                href="/"
                className="inline-flex items-center mb-6 text-2xl leading-8 font-semibold text-gray-900 lg:mb-10 dark:text-white"
              >
                <img className="h-10 mr-2" src={Logo} alt="logo" />
              </Link>
              <div className="flex">
                <svg
                  className="w-5 h-5 mr-2 text-primary-600 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <h3 className="mb-2 text-xl leading-none font-bold text-gray-900 dark:text-white">
                    Get started quickly
                  </h3>
                  <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                    Integrate with developer-friendly APIs or choose low-code.
                  </p>
                </div>
              </div>
              <div className="flex pt-8">
                <svg
                  className="w-5 h-5 mr-2 text-primary-600 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <h3 className="mb-2 text-xl leading-none font-bold text-gray-900 dark:text-white">
                    Support any business model
                  </h3>
                  <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                    Host code that you don't want to share with the world in
                    private.
                  </p>
                </div>
              </div>
              <div className="flex pt-8">
                <svg
                  className="w-5 h-5 mr-2 text-primary-600 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <div>
                  <h3 className="mb-2 text-xl leading-none font-bold text-gray-900 dark:text-white">
                    Join millions of businesses
                  </h3>
                  <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                    Flowbite is trusted by ambitious startups and enterprises of
                    every size.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-6 text-center lg:hidden">
            <Link
              href="#"
              className="inline-flex items-center text-2xl leading-8 font-semibold text-gray-900 lg:hidden dark:text-white"
            >
              <img className="w-8 h-8 mr-2" src={Logo} alt="logo" />
              Flowbite
            </Link>
          </div>
          <div className="w-full col-span-6 ml-auto mr-auto bg-white rounded-lg shadow-black dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Crie sua conta
              </h1>
              <div className="items-center sm:flex">
                <Link
                  href="#"
                  className="w-full inline-flex items-center justify-center py-2 px-5 text-sm leading-5 font-medium text-gray-900 focus:outline-none bg-white rounded-lg border dark:border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:shadow-black focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 mr-2 mb-2 sm:mb-0"
                >
                  <svg
                    className="w-5 h-5 mr-2"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_13183_10121)">
                      <path
                        d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
                        fill="#3F83F8"
                      ></path>
                      <path
                        d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
                        fill="#34A853"
                      ></path>
                      <path
                        d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
                        fill="#FBBC04"
                      ></path>
                      <path
                        d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
                        fill="#EA4335"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_13183_10121">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.5)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  Entrar com o Google
                </Link>
                <Link
                  href="#"
                  className="w-full inline-flex items-center justify-center py-2 px-5 text-sm leading-5 font-medium text-gray-900 focus:outline-none bg-white rounded-lg border dark:border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:shadow-black focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Entrar com o GitHub
                </Link>
              </div>
              <div className="flex items-center">
                <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
                <div className="pl-5 pr-5 text-center text-gray-500 dark:text-gray-400">
                  ou
                </div>
                <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
              </div>
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Seu email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Senha
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirme a senha
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                  />
                </div>
                <div className="flex items-center justify-between">
                  {/* Rememeber me */}
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Ao se inscrever, você está criando uma conta Flowbite e
                        concorda com os{" "}
                        <Link
                          to="/terms"
                          className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                        >
                          Termos de Serviço
                        </Link>{" "}
                        e a{" "}
                        <Link
                          to="/privacy"
                          className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                        >
                          Política de Privacidade
                        </Link>{" "}
                        da Flowbite.
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Inscrever-se
                </button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Você já tem uma conta?{" "}
                  <Link
                    to="/login"
                    className="font-medium text-primary-600 hover:underline"
                  >
                    Entrar
                  </Link>
                  .
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
