export default function Profile() {
  return (
    <div>
      <h1>Profile</h1>
      <button
        onClick={() => {
          window.location.href = "/";
        }}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign Out
      </button>
    </div>
  );
}
